import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import ContactForm from "../components/contact-form"

const AboutPage = () => (
  <Layout pageTitle="About">
    <h1>Hi, this page is about ME!</h1>
    <div style={{ maxWidth: `1000px`, marginBottom: `1.45rem` }}>
      <article>
        <p>My name is Gev, I am just a dude.</p>
        <p>I am a dude in his <s>mid</s> late 20s, and I built this website for three reasons:
          <br/><br/>
          -- First reason was to see whether I still remember how to make websites. Turns out I do still got it, but man was it a struggle. Modern webdev is so complicated... I just want to go back to the good old days of html tables for data.
          <br/><br/>
          -- Second reason is to document things that took me a while to figure out and I know I will eventually need to remember again. This way, when I can't remember and my own writing doesn't make sense to me, I've got no one but myself to blame.
          <br/><br/>
          -- Third reason is to document my potential journey into content creation, if I ever decide to get off my lazy butt and get stuff going.
          
          </p>

        <p>That's all I've got for now at least, maybe I will come back and update this page eventually at some point, who knows?</p>          
      </article>

      {/* <section>
        <ContactForm/>
      </section> */}
    </div>
    <hr/>
  </Layout>
)

export default AboutPage